import { navigate } from 'gatsby'
import jwt_decode from "jwt-decode"

export const isBrowser = () => typeof window !== "undefined"

export const useGetCurrentEditor = () => getEditor()

export const getEditor = () => isBrowser() && window.localStorage.getItem("editor")
  ? JSON.parse(window.localStorage.getItem("editor"))
  : false

export const setCurrentEditor = (data, callback = () => { }) => {
  window.localStorage.setItem("editor", JSON.stringify(data))
  callback()
}

export function requireAuthenticatedEditor() {
  if (!isLoggedIn()) return navigate("/login")
}

export const isLoggedIn = () => {
  const jwt = isBrowser() && window.localStorage.getItem("jwt-editor")
  const now = Math.round(+new Date() / 1000)

  if (jwt === null) return false
  const decodedToken = jwt_decode(jwt)
  console.log({ jwt, decodedToken, now })

  // expired jwt: eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOjYsImV4cCI6MTY3Nzg0MzQ0M30.2ZAa_VWcCyRRrFF28Z7jUEYkG07BcETccXuUBl36mLE

  if (now > decodedToken.exp) return false
  return !!jwt
}

export const loginExpired = () => {
  const jwt = isBrowser() && window.localStorage.getItem("jwt-editor")
  const now = Math.round(+new Date() / 1000)

  if (jwt === null) return true

  const decodedToken = jwt_decode(jwt)

  if (now > decodedToken.exp) return true
  return false
}

export function validateAuthenticatedUser() {
  if (loginExpired()) {
    navigate("/login")
    return false
  }
  return true
}

export const logout = () => {
  window.localStorage.removeItem('jwt-editor')
  navigate('/login')
}
